@use "src/styles/themes/symbiot-main/colors" as colors;

$ph-direction:            ltr !default;
$ph-bg:                   colors.$bg;
$ph-color:                #ced4da !default;
$ph-border:               none;
$ph-border-radius:        2px !default;

$ph-cols:                 12 !default;
$ph-cols-remove-odd:      true !default;
$ph-gutter:               30px !default;
$ph-spacer:               15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration:   0.8s !default;
@import "node_modules/placeholder-loading/src/scss/placeholder-loading";
