@use "src/styles/themes/symbiot-main/variables" as variables;
@import 'normalize.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --doc-height: 100%;
}

@import "@angular/cdk/overlay-prebuilt.css";
@import "styles/themes/symbiot-main/theme";
@import "styles/themes/symbiot-main/colors";

.column {
  flex: 1;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $bg;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: $text-body;
}

a:visited {
  color: inherit;
}

mat-dialog-container {
  --mdc-dialog-container-shape: 12px;
}

pre {
  white-space: pre-wrap;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0,
  "wght" 400,
  "GRAD" 0,
  "opsz" 48 !important;
}

.firebase-emulator-warning {
  display: none;
}

mat-card {
  --mdc-shape-medium: 16px;
  padding: 24px 32px;
  margin-bottom: 16px;

  mat-title,
  mat-title a {
    color: $textTitle;
  }
}

mat-divider.mat-divider {
  border-color: $states-opacity-Hover-0;
}

.error {
  &, .mdc-form-field label {
    color: $system-red;
  }
}

.hidden {
  display: none;
}

img {
  max-width: 100%;
}

.rotate-right {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

/* Rotate mat-icon 180 degrees (upside down) */
.rotate-upside-down {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

/* Rotate mat-icon 270 degrees to the left */
.rotate-left {
  transform: rotate(270deg);
  transition: transform 0.3s ease-in-out;
}

.spacer {
  flex: 1 1 auto;
}

.container {
  max-width: variables.$max-desktop-width;
  min-width: variables.$min-mobile-width;
  margin: 0 auto;

  @media (max-width: variables.$max-mobile-width) {
    max-width: 100vw;
  }
}

.content-mobile + * {
  @media screen and (min-width: variables.$to-min-mobile-width) {
    display: none;
  }
}

.content-desktop + * {
  @media screen and (max-width: variables.$max-mobile-width) {
    display: none;
  }
}

.top-menu-mobile + * {
  @media screen and (min-width: variables.$to-min-mobile-width) {
    display: none;
  }
}

.top-menu-desktop + * {
  @media screen and (max-width: variables.$max-mobile-width) {
    display: none;
  }
}

.fullscreen-dialog {
  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: 0;
  }
}

.text-center {
  text-align: center;
}

mat-button-toggle-group {
  height: 40px;

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px;
  }
}

.opacity30 {
  opacity: 0.3;
}
