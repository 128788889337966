@use "sass:color";

$textTitle: #353d4c;
$textSubtitle: #525a66;
$textLabel: #b2b5ba;
$textAdditional: #edeff2;
$text-body: #707680;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
$primary100: #1c434b;
$primary80: #338894;
$primary60: #4a99aa;
$primary40: #61aebf;
$primary20: #d6edf1;
$primary15: #eafbfe;
$primary10: #eafbfe;
$primary5: #fcfdff;
$modalSheets: #fcfdff;
$orange100: #e88a27;
$orange50: #f5a24a;
$orange15: #fef0e1;

$neutral80: #353d4c;
$neutral70: #525a66;
$neutral60: #6d737c;
$neutral50: #a8abb1;

$transparent-white80: #f9feffcc;
$transparent-white100: #ffffff;
$transparent-white40: #ffffff66;
$transparent-blue: #2a606d4d;
$bg: #f3f9fb;
$states-Hover-0: #2a606d;
$states-opacity-Hover-0: #2a606d0d;
$states-opacity-Hover-4: #004a5b99;

$accent-50: #f5a24a;
$accent-100: #e88a27;

$system-red: #ff5353;
$system-green-100: #1DB485;

$states-opacity-hover-1: #51838e1a;

$palette: (
  50: #00ff12,
  100: #c5e9c6,
  200: #89de76,
  300: #389835,
  400: #4fd967,
  500: $primary80,
  600: #38a92b,
  700: #82e76c,
  800: #7efd35,
  900: #76cb0f,
  A100: #8c9eff,
  A200: $primary80,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
