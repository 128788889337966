@use "./colors" as colors;

@mixin textStyles($size, $height, $fontWeight, $letterSpacing, $color) {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $size;
  line-height: $height;
  /* or 143% */

  letter-spacing: $letterSpacing;

  color: $color;
}

.displaySmall {
  @include textStyles(36px, 44px, 400, inherit, inherit);
}

.bodyLarge {
  @include textStyles(16px, 24px, 400, 0.5px, #707680);
}

.bodyMedium {
  @include textStyles(14px, 20px, 400, 0.25px, inherit);
}

.bodySmall {
  @include textStyles(12px, 16px, 400, 0.4px, #707680);
}

.bodyLargeNeutral70 {
  @include textStyles(16px, 24px, 400, 0.5px, colors.$neutral70);
}

.bodyMediumNeutral70 {
  @include textStyles(14px, 20px, 400, 0.25px, colors.$neutral70);
}

.titleMediumPrimary80 {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$primary80);
}

.bodyMediumNeutral60 {
  @include textStyles(14px, 20px, 400, 0.25px, colors.$neutral70);
}

.titleLarge {
  @include textStyles(18px, 28px, 400, 0.1px, colors.$neutral80);
}

.titleLargeNeutral70 {
  @include textStyles(18px, 28px, 400, inherit, colors.$neutral70);
}

.titleLargeColor70 {
  @include textStyles(18px, 28px, 400, 0.1px, colors.$neutral70);
}


.titleMedium {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$neutral70);
}

.headlineSmall {
  @include textStyles(22px, 32px, 400, inherit, inherit);
}

.headlineSmallNeutral80 {
  @include textStyles(22px, 32px, 400, inherit, colors.$neutral80);
}

.headlineMediumNeutral80 {
  @include textStyles(28px, 36px, 400, inherit, colors.$neutral80);
}

.headlineLargeNeutral80 {
  @include textStyles(32px, 40px, 400, inherit, colors.$neutral80);
}

.headlineLarge {
  @include textStyles(32px, 40px, 400, inherit, inherit);
}

.titleSmall {
  @include textStyles(12px, 20px, 500, 0.1px, colors.$neutral70);
}

.titleMediumPrimary80 {
  @include textStyles(16px, 24px, 500, 0.1px, colors.$primary80);
}


.titleSmallNeutral50 {
  @include textStyles(10px, 16px, 500, 0.5px, colors.$neutral50);
}

.titleMediumNeutral60 {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$neutral60);
}

.titleMediumNeutral80 {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$neutral80);
}

.labelSmall {
  @include textStyles(10px, 16px, 500, 0.5px, colors.$neutral70);
}

.labelMedium {
  @include textStyles(12px, 16px, 500, 0.5px, colors.$neutral50);
}

.labelLarge {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$neutral50);
}

.buttonMediumPrimary10 {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$primary10);
}

.buttonMediumNeutral70 {
  @include textStyles(14px, 20px, 500, 0.1px, colors.$neutral70);
}

.discord-info-title {
  @include textStyles(30px, 1.3, 400, 1px, colors.$neutral80);
}

.no-chat-selected {
  @include textStyles(14px, 1.4, 500, 0.1px, colors.$primary60);
}

.subscriptions-title {
  @include textStyles(32px, 1.4, 400, 0.1px, colors.$neutral80);
}

.textStatusGreen100{
  @include textStyles(14px, 20px, 500, 0.1px, colors.$system-green-100)
}

.subscriptions-description {
  @include textStyles(14px, 1.4, 400, 0.25px, colors.$neutral60);
}
