@use "@angular/material" as mat;
@use "./colors" as colors;
@use "./_fonts";

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: $transparent-blue;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: (lighten($transparent-blue, 10%));
}

@mixin colorSet($color) {
  &,
  &.mat-mdc-button .mdc-button__label {
    color: $color;
  }
}

.colorBg {
  @include colorSet(colors.$bg);
}

.colorTransparentWhite100,
.colorTransparentWhite100:visited {
  @include colorSet(colors.$transparent-white100);
}

.colorAccent100,
.colorAccent100:visited {
  @include colorSet(colors.$accent-100);
}

.colorPrimary80,
.colorPrimary80:visited {
  @include colorSet(colors.$primary80);
}

.colorPrimary60,
.colorPrimary60:visited {
  color: colors.$primary60;
}

.colorPrimary40,
.colorPrimary40:visited {
  @include colorSet(colors.$primary40);
}

.colorPrimary20,
.colorPrimary20:visited {
  @include colorSet(colors.$primary20);
}

.colorPrimary10,
.colorPrimary10:visited {
  @include colorSet(colors.$primary10);
}

.colorOrange100,
.colorOrange100:visited {
  @include colorSet(colors.$orange100);
}

.colorOrange50,
.colorOrange50:visited {
  @include colorSet(colors.$orange50);
}

.colorOrange15,
.colorOrange15:visited {
  @include colorSet(colors.$orange15);
}

.colorNeutral80,
.colorNeutral80:visited {
  @include colorSet(colors.$neutral80);
}

.colorNeutral70,
.colorNeutral70:visited {
  @include colorSet(colors.$neutral70);
}

.colorNeutral60,
.colorNeutral60:visited {
  @include colorSet(colors.$neutral60);
}

.colorNeutral50,
.colorNeutral50:visited {
  @include colorSet(colors.$neutral50);
}

.bgPrimary80 {
  background-color: colors.$primary80;
  color: colors.$bg;

  a:hover,
  mat-icon:hover {
    color: colors.$orange50;
  }

  .mat-mdc-button {
    --mdc-text-button-label-text-color: colors.$primary20;
  }
}

.bgPrimary60 {
  background-color: colors.$primary60;
  color: colors.$bg;
}

.bgPrimary40 {
  background-color: colors.$primary40;
  color: colors.$bg;
}

.bgPrimary20 {
  background-color: colors.$primary20;
  color: colors.$bg;
}

.bgPrimary10 {
  background-color: colors.$primary10;
  color: colors.$bg;
}

.bgPrimaryWhite100,
.bgPrimaryWhite100 a {
  background-color: colors.$transparent-white100;
  color: colors.$primary80;
}

.textLabel {
  color: colors.$textLabel;
  font-size: 11px;
  letter-spacing: 0.5px;
}

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
  background-color: colors.$states-Hover-0;
  opacity: 30%;
}

body {
  .shadow-elevation-light-upside-down {
    box-shadow: 0px -4px 4px 0px rgba(74, 153, 170, 0.03),
    0px -2px 4px 6px rgba(74, 153, 170, 0.05);
  }

  .shadow-elevation-light-active-card {
    box-shadow: 0 2px 3px 0 rgba(66, 148, 166, 0.1),
    0 6px 10px 2px rgba(74, 153, 170, 0.15);
  }

  // dialog shadow
  --mdc-dialog-container-elevation: 0 2px 3px 0 rgba(66, 148, 166, 0.1),
  0 6px 10px 2px rgba(74, 153, 170, 0.15);

  .mat-mdc-raised-button[disabled],
  .mdc-button[disabled] {
    opacity: 0.38;
  }

  .mdc-button.mdc-button--outlined {
    border-color: colors.$primary80;
    color: colors.$neutral70;
  }

  .mdc-button,
  .mdc-button.mdc-button--raised {
    //margin: 0 10px 10px 0;
    --mdc-shape-small: 100px;
  }

  .mat-mdc-raised-button,
  .mat-mdc-raised-button[disabled] {
    background-color: colors.$primary80;
    --mdc-protected-button-disabled-container-opacity: 0.38;
  }

  .mat-mdc-raised-button[disabled] {
    color: colors.$primary10;
  }

  // Input border default
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: colors.$primary80;
  }

  .mat-drawer-container,
  .mat-drawer {
    background-color: unset;
  }

  .mat-drawer-side {
    border: unset;
  }

  .cdk-virtual-scroll-content-wrapper {
    display: flex;
    background-color: colors.$transparent-white100;
  }

  mat-button-toggle-group.mat-button-toggle-group {
    border-color: colors.$primary80;
    border-radius: 100px;

    mat-button-toggle {
      @extend .labelLarge, .colorNeutral60;

      &.mat-button-toggle-checked {
        @extend .colorPrimary10;
        background-color: colors.$primary80;
      }
    }

  }
}

.chat-item {
  .mdc-button__label {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}

app-chat-controls {
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    background-color: colors.$transparent-white100;
  }
}
