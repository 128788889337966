$max-tablet-width: 999px !default;
$max-mobile-width: 999px !default;
// it need be used to min-width
$to-min-mobile-width: 1000px !default;
$min-mobile-width: 320px !default;
$centering-width: 1440px !default;
$max-desktop-width: 1440px !default;

$header-height: 64px !default;
$bottom-menu-height: 86px !default;
$credits-block-height: 183px !default;
$content-body-max-width: 1096px !default;
$content-body-height: calc(100vh - $header-height) !default;
$chat-list-height: calc(100vh - #{$header-height} -  #{$header-height} - #{$credits-block-height});
$chat-list-height-mobile: calc(100vh - #{$header-height} - #{$header-height});

@media screen and (max-width: $max-mobile-width) {
  $content-body-height: calc(100vh - #{$header-height} - #{$bottom-menu-height});
}

//:root {
//  --content-body-height: calc(100vh - var(--header-height));
//}
//
//@media screen and (max-width: var(--max-mobile-width)) {
//  :root {
//    --content-body-height: calc(100% - var(--header-height) - var(--bottom-menu-height));
//  }
//}
